import { TypedTypePolicies } from '@/api/__generated__/type-policies';
import { relayStylePagination } from '@apollo/client/utilities';

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      productsByShop: relayStylePagination(['shopId']),
      productCollections: relayStylePagination(),
      showCardsByShow: relayStylePagination(['showId']),
      searchProductsByShop: relayStylePagination(['shopId', 'query']),
      showsByShop: relayStylePagination(['shopId']),
      taxCategories: relayStylePagination(),
      searchTaxCategories: relayStylePagination(['query']),
      taxCategoriesByCategory: relayStylePagination(['categoryId']),
      shopOrdersByShop: relayStylePagination(['shopId']),
      shops: relayStylePagination(),
      searchShops: relayStylePagination(['query']),
      shop(_, { args, toReference }) {
        return toReference({
          __typename: 'Shop',
          id: args?.id
        });
      },
      product(_, { args, toReference }) {
        return toReference({
          __typename: 'ProductDetails',
          id: args?.id
        });
      },
      category(_, { args, toReference }) {
        return toReference({
          __typename: 'Category',
          id: args?.id
        });
      },
      checkout(_, { args, toReference }) {
        return toReference({
          __typename: 'Checkout',
          id: args?.id
        });
      },
      taxCategory(_, { args, toReference }) {
        return toReference({
          __typename: 'TaxCategory',
          id: args?.id
        });
      }
    }
  },
  Show: {
    fields: {
      showCards: relayStylePagination()
    }
  }
};
