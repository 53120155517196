import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useInventoryItemsByBarcodeQuery, useSearchProductsByShop, useShopProducts } from '@/api';
import { useDebounce } from 'react-use';
import onScan from 'onscan.js';

type UseFilterShopProductsOptions = {
  skip?: boolean;
  queryParam?: string;
  debounceDuration?: number;
  disableQueryParam?: boolean;
  enableScanning?: boolean;
};

export function useFilterShopProducts(shopId: string, options?: UseFilterShopProductsOptions) {
  const { query, replace, pathname } = useRouter();

  const queryParam = options?.queryParam ?? 'pq';
  const disableQueryParam = options?.disableQueryParam ?? false;
  const debounceDuration = options?.debounceDuration ?? 300;

  const [localQuery, setLocalQuery] = useState<string>('');
  const currentQuery = disableQueryParam ? localQuery : (query[queryParam] as string) ?? '';
  const [searchQuery, setSearchQuery] = useState<string>(currentQuery);

  useEffect(() => {
    if (options?.enableScanning) {
      // Attach onScan
      if (typeof window !== 'undefined') {
        console.info('Barcode scanner attached.');
        onScan.attachTo(document, {
          async onScan(scanned: string, _: number) {
            console.info(`Scanned: ${scanned}`);
            setSearchQuery(scanned);
          },
          avgTimeByChar: 80,
          reactToKeyDown: true,
          reactToPaste: false,
          ignoreIfFocusOn: true
        });
        return () => {
          console.info('Barcode scanner detached.');
          onScan.detachFrom(document);
        };
      }
    }
  }, [options]);

  const {
    loading: loadingProducts,
    products: baseProducts,
    hasMoreProducts: haseMoreBaseProducts,
    fetchingMoreProducts: fetchingMoreBaseProducts,
    fetchMoreProducts: fetchMoreBaseProducts
  } = useShopProducts(shopId, {
    skip: options?.skip || Boolean(currentQuery)
  });

  const {
    loading: loadingProductResults,
    productResults,
    hasMoreProductResults,
    fetchingMoreProductResults,
    fetchMoreProductResults
  } = useSearchProductsByShop(shopId, currentQuery, {
    skip: options?.skip || !Boolean(currentQuery)
  });

  const { data: barcodeInventoryItemResults, loading: loadingBarcodeInventoryItemResults } =
    useInventoryItemsByBarcodeQuery({
      variables: {
        shopId,
        barcode: currentQuery,
        first: 10
      },
      skip: options?.skip || !Boolean(currentQuery)
    });

  const barcodeProductResults = barcodeInventoryItemResults?.inventoryItemsByBarcode?.nodes?.map((i) => i.product);

  const updateQueryParam = useCallback(
    async (searchQuery: string) => {
      if (searchQuery) {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug, [queryParam]: searchQuery }
        });
      } else {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug }
        });
      }
    },
    [pathname, query, replace]
  );

  const search = useCallback(
    async (searchQuery: string) => {
      if (disableQueryParam) {
        setLocalQuery(searchQuery);
      } else {
        await updateQueryParam(searchQuery);
      }
    },
    [disableQueryParam, updateQueryParam]
  );

  useDebounce(
    () => {
      if (searchQuery !== currentQuery) search(searchQuery);
    },
    debounceDuration,
    [searchQuery]
  );

  let products = baseProducts;
  let hasMoreProducts = haseMoreBaseProducts;
  let fetchingMoreProducts = fetchingMoreBaseProducts;
  let fetchMoreProducts = fetchMoreBaseProducts;

  if (Boolean(currentQuery)) {
    products = barcodeProductResults?.length ? barcodeProductResults : productResults;
    hasMoreProducts = hasMoreProductResults;
    fetchingMoreProducts = fetchingMoreProductResults;
    fetchMoreProducts = fetchMoreProductResults;
  }

  return {
    loading: loadingProducts,
    searching: loadingBarcodeInventoryItemResults || loadingProductResults,
    products,
    hasMoreProducts,
    fetchingMoreProducts,
    fetchMoreProducts,
    searchQuery,
    setSearchQuery,
    search
  };
}
