import { useCallback, useState } from 'react';
import {
  CheckoutFragment,
  CreateCustomerAddressDocument,
  CreateCustomerAddressMutation,
  CreateCustomerAddressMutationVariables,
  CreateCustomerCardDocument,
  CreateCustomerCardMutation,
  CreateCustomerCardMutationVariables,
  CreateOrGetGuestCustomerDocument,
  CreateOrGetGuestCustomerMutation,
  CreateOrGetGuestCustomerMutationVariables,
  CustomerAddressFragment,
  CustomerAddressFragmentDoc,
  CustomerPaymentMethodFragment,
  CustomerPaymentMethodFragmentDoc,
  CustomerPaymentMethodStatus,
  DeleteCustomerAddressDocument,
  DeleteCustomerAddressMutation,
  DeleteCustomerAddressMutationVariables,
  DeleteCustomerPaymentMethodDocument,
  DeleteCustomerPaymentMethodMutation,
  DeleteCustomerPaymentMethodMutationVariables,
  useCustomerPaymentMethodQuery,
  useViewerCustomerQuery,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  UpdateCustomerDocument,
  CreateCustomerAddressPosMutation,
  CreateCustomerAddressPosMutationVariables,
  CreateCustomerAddressPosDocument
} from '@/api';
import { useRelayMutation } from '@/lib';
import { useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeCardElement, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { Reference, useApolloClient } from '@apollo/client';

export function useViewerCustomer() {
  const { data, ...rest } = useViewerCustomerQuery();

  return {
    customer: data?.viewer?.customer ?? null,
    customerAddresses: (data?.viewer?.customer?.customerAddresses?.nodes as CustomerAddressFragment[]) ?? [],
    customerPaymentMethods:
      (data?.viewer?.customer?.customerPaymentMethods?.nodes as CustomerPaymentMethodFragment[]) ?? [],
    checkouts: (data?.viewer?.customer?.checkouts?.nodes as CheckoutFragment[]) ?? [],
    ...rest
  };
}

export function useCreateCustomerAddress() {
  return useRelayMutation<
    CreateCustomerAddressMutation,
    CreateCustomerAddressMutationVariables,
    'createCustomerAddress'
  >(CreateCustomerAddressDocument, 'createCustomerAddress', {
    update(cache, { data }) {
      if (data?.createCustomerAddress?.customerAddress) {
        const customerAddressRef = cache.writeFragment<CustomerAddressFragment>({
          fragment: CustomerAddressFragmentDoc,
          fragmentName: 'CustomerAddress',
          data: data.createCustomerAddress.customerAddress
        });

        cache.modify({
          id: data.createCustomerAddress.customerAddress.customerId,
          fields: {
            customerAddresses(existingCustomerAddresses = { nodes: [] }) {
              return {
                ...existingCustomerAddresses,
                nodes: [...(existingCustomerAddresses.nodes ?? []), customerAddressRef]
              };
            }
          }
        });
      }
    }
  });
}

export function useCreateCustomerAddressPos() {
  return useRelayMutation<
    CreateCustomerAddressPosMutation,
    CreateCustomerAddressPosMutationVariables,
    'createCustomerAddressPos'
  >(CreateCustomerAddressPosDocument, 'createCustomerAddressPos', {
    update(cache, { data }) {
      if (data?.createCustomerAddressPos?.customerAddress) {
        const customerAddressRef = cache.writeFragment<CustomerAddressFragment>({
          fragment: CustomerAddressFragmentDoc,
          fragmentName: 'CustomerAddress',
          data: data.createCustomerAddressPos.customerAddress
        });

        cache.modify({
          id: data.createCustomerAddressPos.customerAddress.customerId,
          fields: {
            customerAddresses(existingCustomerAddresses = { nodes: [] }) {
              return {
                ...existingCustomerAddresses,
                nodes: [...(existingCustomerAddresses.nodes ?? []), customerAddressRef]
              };
            }
          }
        });
      }
    }
  });
}

export function useCreateOrGetGuestCustomer() {
  return useRelayMutation<
    CreateOrGetGuestCustomerMutation,
    CreateOrGetGuestCustomerMutationVariables
    ,
    'createOrGetGuestCustomer'
  >(
    CreateOrGetGuestCustomerDocument,
    'createOrGetGuestCustomer'
  );
}

export function useDeleteCustomerAddress() {
  const client = useApolloClient();

  return useRelayMutation<
    DeleteCustomerAddressMutation,
    DeleteCustomerAddressMutationVariables,
    'deleteCustomerAddress'
  >(DeleteCustomerAddressDocument, 'deleteCustomerAddress', {
    optimisticResponse({ input }) {
      const customerAddress = client.cache.readFragment<CustomerAddressFragment>({
        fragment: CustomerAddressFragmentDoc,
        fragmentName: 'CustomerAddress',
        id: input.customerAddressId
      });

      if (customerAddress) {
        return {
          __typename: 'Mutation',
          deleteCustomerAddress: {
            __typename: 'DeleteCustomerAddressPayload',
            customerAddress,
            errors: null
          }
        };
      }
      return {
        __typename: 'Mutation',
        deleteCustomerAddress: {
          __typename: 'DeleteCustomerAddressPayload',
          customerAddress: null,
          errors: null
        }
      };
    },
    update(cache, { data }) {
      if (data?.deleteCustomerAddress?.customerAddress) {
        cache.modify({
          id: data.deleteCustomerAddress.customerAddress.customerId,
          fields: {
            customerAddresses(existingCustomerAddresses = { nodes: [] }, { readField }) {
              return {
                ...existingCustomerAddresses,
                nodes: existingCustomerAddresses.nodes.filter(
                  (customerAddressNode: Reference) =>
                    readField('id', customerAddressNode) !== data.deleteCustomerAddress.customerAddress?.id
                )
              };
            }
          }
        });
      }
    }
  });
}

export function useCreateCustomerCard() {
  return useRelayMutation<CreateCustomerCardMutation, CreateCustomerCardMutationVariables, 'createCustomerCard'>(
    CreateCustomerCardDocument,
    'createCustomerCard'
  );
}

export function useDeleteCustomerPaymentMethod() {
  const client = useApolloClient();

  return useRelayMutation<
    DeleteCustomerPaymentMethodMutation,
    DeleteCustomerPaymentMethodMutationVariables,
    'deleteCustomerPaymentMethod'
  >(DeleteCustomerPaymentMethodDocument, 'deleteCustomerPaymentMethod', {
    optimisticResponse({ input }) {
      const customerPaymentMethod = client.cache.readFragment<CustomerPaymentMethodFragment>({
        fragment: CustomerPaymentMethodFragmentDoc,
        fragmentName: 'CustomerPaymentMethod',
        id: input.customerPaymentMethodId
      });

      if (customerPaymentMethod) {
        return {
          __typename: 'Mutation',
          deleteCustomerPaymentMethod: {
            __typename: 'DeleteCustomerPaymentMethodPayload',
            customerPaymentMethod,
            errors: null
          }
        };
      }
      return {
        __typename: 'Mutation',
        deleteCustomerPaymentMethod: {
          __typename: 'DeleteCustomerPaymentMethodPayload',
          customerPaymentMethod: null,
          errors: null
        }
      };
    },
    update(cache, { data }) {
      if (data?.deleteCustomerPaymentMethod?.customerPaymentMethod) {
        cache.modify({
          id: data.deleteCustomerPaymentMethod.customerPaymentMethod.customerId,
          fields: {
            customerPaymentMethods(existingCustomerPaymentMethods = { nodes: [] }, { readField }) {
              return {
                ...existingCustomerPaymentMethods,
                nodes: existingCustomerPaymentMethods.nodes.filter(
                  (customerPaymentMethodNode: Reference) =>
                    readField('id', customerPaymentMethodNode) !==
                    data.deleteCustomerPaymentMethod.customerPaymentMethod?.id
                )
              };
            }
          }
        });
      }
    }
  });
}

export type SetupCustomerCardOptions = {
  onComplete?: (customerCard: CustomerPaymentMethodFragment) => void;
};

export type SetupCustomerCardArgs = {
  customerId: string;
  name?: string;
  postalCode?: string;
  card: StripeCardElement | StripeCardNumberElement | { token: string };
  isDefault?: boolean;
};

type SetupResult = { setupIntent: SetupIntent; error?: undefined } | { setupIntent?: undefined; error: StripeError };

export function useSetupCustomerCard(
  options: SetupCustomerCardOptions
): [(args: SetupCustomerCardArgs) => Promise<SetupResult | null>, { loading: boolean }] {
  const stripe = useStripe();
  const client = useApolloClient();
  const [createCustomerCard, { customerCard }] = useCreateCustomerCard();
  const [loading, setLoading] = useState<boolean>(false);

  useCustomerPaymentMethodQuery({
    variables: {
      id: customerCard?.id!
    },
    pollInterval: 1000,
    skip: !Boolean(customerCard?.id) || !loading,
    onCompleted(data) {
      if (data?.customerPaymentMethod?.status === CustomerPaymentMethodStatus.VERIFIED) {
        const customerCardRef = client.cache.writeFragment<CustomerPaymentMethodFragment>({
          fragmentName: 'CustomerPaymentMethod',
          fragment: CustomerPaymentMethodFragmentDoc,
          data: data.customerPaymentMethod
        });

        client.cache.modify({
          id: data.customerPaymentMethod.customerId,
          fields: {
            customerPaymentMethods(existingCustomerPaymentMethods = { nodes: [] }) {
              return {
                ...existingCustomerPaymentMethods,
                nodes: [...(existingCustomerPaymentMethods.nodes ?? []), customerCardRef]
              };
            }
          }
        });

        options?.onComplete?.(data.customerPaymentMethod);
        setLoading(false);
      }
    }
  });

  const setupCustomerCard = useCallback(
    async ({ customerId, card, name, postalCode, isDefault }: SetupCustomerCardArgs): Promise<SetupResult | null> => {
      if (stripe) {
        try {
          setLoading(true);
          const { customerCard } = await createCustomerCard({
            customerId,
            isDefault
          });

          if (customerCard?.setupSecret) {
            return await stripe.confirmCardSetup(
              customerCard.setupSecret,
              {
                payment_method: {
                  billing_details: {
                    name,
                    address: {
                      postal_code: postalCode
                    }
                  },
                  card
                }
              },
              {
                handleActions: true
              }
            );
          } else {
            console.error('Error finding setup secret!');
          }
        } catch (error) {
          console.error('Error setting up customer card:', error);
          setLoading(false);
        }
      } else {
        console.error('Stripe elements not initialized!');
      }
      return null;
    },
    [stripe]
  );

  return [setupCustomerCard, { loading }];
}

export function useUpdateCustomer() {
  return useRelayMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables, 'updateCustomer'>(
    UpdateCustomerDocument,
    'updateCustomer'
  );
}
