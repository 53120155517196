import { QueryHookOptions } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useProductCollectionsQuery } from '../__generated__/hooks';
import {
  ProductCollectionListItemFragment,
  ProductCollectionsQuery,
  SearchProductsByShopQuery
} from '../__generated__/operations';

export function useShopProductCollections(shopId: string, options?: QueryHookOptions<ProductCollectionsQuery, any>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useProductCollectionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      shopId
    },
    ...options
  });
  const [fetchingMoreProductCollections, setFetchingMoreProductCollections] = useState<boolean>(false);

  const fetchMoreProductCollections = useCallback(async () => {
    setFetchingMoreProductCollections(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.productCollections?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreProductCollections(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreProductCollections]);

  return {
    productCollections: (data?.productCollections?.edges?.map((edge) => edge.node) ??
      []) as ProductCollectionListItemFragment[],
    hasMoreProductCollections: data?.productCollections?.pageInfo.hasNextPage ?? false,
    fetchingMoreProductCollections,
    fetchMoreProductCollections,
    ...rest
  };
}

export function useSearchProductCollectionsByShop(
  shopId: string,
  query: string,
  options?: QueryHookOptions<SearchProductsByShopQuery, any>
) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useProductCollectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      shopId,
      filter: {
        name: {
          contains: query
        }
      }
    },
    ...options
  });
  const [fetchingMoreProductCollectionResults, setFetchingMoreProductCollectionResults] = useState<boolean>(false);

  const fetchMoreProductCollectionResults = useCallback(async () => {
    setFetchingMoreProductCollectionResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.productCollections?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreProductCollectionResults(false);
    }
  }, [data, setFetchingMoreProductCollectionResults]);

  return {
    productCollectionResults: (data?.productCollections?.edges?.map((edge) => edge.node) ??
      []) as ProductCollectionListItemFragment[],
    hasMoreProductCollectionResults: data?.productCollections?.pageInfo.hasNextPage ?? false,
    fetchingMoreProductCollectionResults,
    fetchMoreProductCollectionResults,
    ...rest
  };
}
