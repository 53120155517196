import {
  AddVariantCardToShowDocument,
  AddVariantCardToShowMutation,
  AddVariantCardToShowMutationVariables,
  CreateShowDocument,
  CreateShowMutation,
  CreateShowMutationVariables,
  ShowCardFragment,
  ShowCardFragmentDoc,
  useCheckShowSlugQuery,
  useShowBySlugQuery,
  useVariantAddedToShowSubscription,
  useShopShowsQuery,
  ShopShowsQuery,
  ShowFragment,
  ShopShowsDocument,
  ShopShowsQueryVariables,
  FeaturedShowCardFragment,
  FeaturedShowCardFragmentDoc,
  AcceptAffiliateInviteMutation,
  AcceptAffiliateInviteMutationVariables,
  AcceptAffiliateInviteDocument,
  DeclineAffiliateInviteMutation,
  DeclineAffiliateInviteDocument,
  DeclineAffiliateInviteMutationVariables,
  useShowQuery,
  useInventoryItemsQuantitySubscription,
  useProductsQuantitySubscription,
  FbCommentsSessionByShowQuery,
  FbCommentsSessionByShowQueryVariables,
  FbCommentsSessionByShowDocument,
  FbCommentsSession,
  FbCommentsSessionFragment,
  EnableFbCommentsMutation,
  EnableFbCommentsMutationVariables,
  EnableFbCommentsDocument,
  DisableFbCommentsMutation,
  DisableFbCommentsMutationVariables,
  DisableFbCommentsDocument,
  useFbCommentsSessionByShowQuery,
  useFbCommentsStatusByShowSubscription,
  FbCommentsSessionStatus,
  FbCommentsSessionFragmentDoc,
  Quantity,
  InviteShowAffiliateMutation,
  InviteShowAffiliateMutationVariables,
  InviteShowAffiliateDocument,
  CreateStreamMutation,
  CreateStreamMutationVariables,
  CreateStreamDocument,
  useStreamBySessionIdQuery,
  useShowBySlugPublicQuery,
  useShowsByShopSlugQuery,
  ShowCardsByShowSlugQuery,
  ShowCardsByShowSlugQueryVariables,
  useShowCardsByShowQuery,
  useShowCardsByShowSlugQuery,
  GetAllStreamsQuery,
  OrderDirection,
  useGetAllStreamsQuery,
  UpdateStreamMutation,
  UpdateStreamMutationVariables,
  UpdateStreamDocument,
  useGetShowInfoForFbPostQuery
} from '@/api';
import { useRelayMutation } from '@/lib';
import { useCallback, useState } from 'react';
import { QueryHookOptions } from '@apollo/client';

const isSSR = typeof window === 'undefined';

type UseShowBySlugOptions = {
  skip?: boolean;
  skipCardAddedSubscription?: boolean;
  onProductQuantityChange?: (productId: string, previous: Quantity, next: Quantity) => void;
  first?: number;
};

export function useShowsByShopSlug(shopSlug: string) {
  const { data, loading, error } = useShowsByShopSlugQuery({
    variables: {
      shopSlug
    }
  });

  const imageData = data?.showsByShopSlug?.edges
    ?.map((edge) => edge.node)
    ?.map((node) => ({
      slug: node.slug,
      image: node.showImages[0]?.image?.transformedSrc // Access only the first image
    }));

  return { imageData, loading, error };
}

export function useShowBySlug(shopSlug: string, showSlug: string, options?: UseShowBySlugOptions) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useShowBySlugQuery({
    variables: {
      shopSlug,
      showSlug
    },
    skip: options?.skip
  });
  const [fetchingMoreCards, setFetchingMoreCards] = useState<boolean>(false);
  const skipSubscriptions = !Boolean(data?.showBySlug?.id) || options?.skip || isSSR;
  const hasMoreCards = data?.showBySlug?.showCards?.pageInfo?.hasNextPage ?? false;
  const endCursor = data?.showBySlug?.showCards?.pageInfo?.endCursor;

  const fetchMoreCards = useCallback(
    async (count: number = 10) => {
      if (endCursor) {
        setFetchingMoreCards(true);
        try {
          await fetchMoreFn({
            variables: {
              first: count,
              cursor: endCursor
            }
          });
        } finally {
          setFetchingMoreCards(false);
        }
      }
      return null;
    },
    [endCursor]
  );

  const show = data?.showBySlug ?? null;
  const cards =
    (data?.showBySlug?.showCards?.edges?.filter((edge) => edge.node!).map((edge) => edge.node) as ShowCardFragment[]) ??
    [];
  const productIds = cards.map((c) => c.variant.productId);
  const inventoryItemIds = cards.map((c) => c.variant.inventoryItem!.id);

  // Subscribe to new show cards
  if (!options?.skipCardAddedSubscription) {
    useShowCardsSubscription(data?.showBySlug?.id!, skipSubscriptions || options?.skipCardAddedSubscription);
  }

  // Subscribe to the product and inventory item quantities
  if (options?.onProductQuantityChange) {
    useProductsQuantitySubscription(productIds, skipSubscriptions, options?.onProductQuantityChange);
    useInventoryItemsQuantitySubscription(inventoryItemIds, skipSubscriptions);
  }

  return {
    show,
    cards,
    featuredCard: (data?.showBySlug?.featuredShowCard as FeaturedShowCardFragment) ?? null,
    fetchMoreCards,
    hasMoreCards,
    fetchingMoreCards,
    ...rest
  };
}

export function useShowBySlugPublic(shopSlug: string, showSlug: string, options?: UseShowBySlugOptions) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useShowBySlugPublicQuery({
    variables: {
      shopSlug,
      showSlug,
      ...options,
      first: options?.first ?? 100
    },
    skip: options?.skip || !shopSlug || !showSlug
  });
  const [fetchingMoreCards, setFetchingMoreCards] = useState<boolean>(false);
  const skipSubscriptions = !Boolean(data?.showBySlug?.id) || options?.skip || isSSR;
  const hasMoreCards = data?.showBySlug?.showCards?.pageInfo?.hasNextPage ?? false;
  const endCursor = data?.showBySlug?.showCards?.pageInfo?.endCursor;

  const fetchMoreCards = useCallback(
    async (count: number = 10) => {
      if (endCursor) {
        setFetchingMoreCards(true);
        try {
          await fetchMoreFn({
            variables: {
              first: count,
              cursor: endCursor
            }
          });
        } finally {
          setFetchingMoreCards(false);
        }
      }
      return null;
    },
    [endCursor]
  );

  const show = data?.showBySlug ?? null;
  const cards =
    (data?.showBySlug?.showCards?.edges?.filter((edge) => edge.node!).map((edge) => edge.node) as ShowCardFragment[]) ??
    [];

  const productIdsList = cards?.map((card) => card.variant?.productId);

  const productIds = cards.map((c) => c.variant.productId);
  const inventoryItemIds = cards.map((c) => c.variant.inventoryItem?.id ?? '');

  // Subscribe to new show cards
  if (!options?.skipCardAddedSubscription) {
    useShowCardsSubscription(data?.showBySlug?.id!, skipSubscriptions || options?.skipCardAddedSubscription);
  }

  // Subscribe to the product and inventory item quantities
  if (options?.onProductQuantityChange) {
    useProductsQuantitySubscription(productIds, skipSubscriptions, options?.onProductQuantityChange);
    useInventoryItemsQuantitySubscription(inventoryItemIds, skipSubscriptions);
  }

  return {
    show,
    cards,
    productIdsList,
    featuredCard: (data?.showBySlug?.featuredShowCard as FeaturedShowCardFragment) ?? null,
    fetchMoreCards,
    hasMoreCards,
    fetchingMoreCards,
    ...rest
  };
}

export function useShowCardsSubscription(showId: string, skip: boolean = true) {
  useVariantAddedToShowSubscription({
    variables: {
      showId
    },
    skip,
    shouldResubscribe: true,
    async onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.variantAddedToShow) {
        const newFeaturedCardRef = client.cache.writeFragment<FeaturedShowCardFragment>({
          fragmentName: 'FeaturedShowCard',
          fragment: FeaturedShowCardFragmentDoc,
          id: subscriptionData.data.variantAddedToShow.id,
          data: subscriptionData.data.variantAddedToShow
        });

        const newCardRef = client.cache.writeFragment<ShowCardFragment>({
          fragmentName: 'ShowCard',
          fragment: ShowCardFragmentDoc,
          id: subscriptionData.data.variantAddedToShow.id,
          data: subscriptionData.data.variantAddedToShow
        });

        client.cache.modify({
          id: subscriptionData.data.variantAddedToShow.showId,
          fields: {
            featuredShowCard() {
              return newFeaturedCardRef;
            },
            showCards(existingCardsRef = { edges: [] }, { readField }) {
              // Filter out variant if it was already featured
              return {
                ...existingCardsRef,
                edges: [
                  { node: newCardRef, cursor: '' },
                  ...(existingCardsRef.edges?.filter(
                    (edge: any) =>
                      readField('variantId', edge.node) !== subscriptionData.data!.variantAddedToShow.variantId
                  ) ?? [])
                ]
              };
            }
          }
        });
      }
    }
  });
}

export function useShow(id: string, skip?: boolean) {
  const { data, ...rest } = useShowQuery({
    variables: {
      id
    },
    skip
  });

  return { show: data?.show, ...rest };
}

export function useStreamBySessionId(sessionId: string, skip?: boolean) {
  const { data, loading, ...rest } = useStreamBySessionIdQuery({
    variables: { sessionId },
    skip: skip || !sessionId,
    fetchPolicy: 'network-only'
  });

  return { stream: data, loading, ...rest };
}

export type StreamCardData = {
  replayUrl: string;
  showName: string;
  showImageUrl: string;
  shopName: string;
  shopImage: string;
  showSlug: string;
  shopSlug: string;
  isLive: boolean;
  isJatango?: boolean;
};

const simplifyApiResponse = (
  data: GetAllStreamsQuery
): { liveStreams: StreamCardData[]; pastStreams: StreamCardData[]; jatangoStreams: StreamCardData[] } => {
  if (!data.allStreams?.nodes) {
    return { liveStreams: [], pastStreams: [], jatangoStreams: [] };
  }

  const liveStreams: StreamCardData[] = [];
  const pastStreams: StreamCardData[] = [];
  const jatangoStreams: StreamCardData[] = [];

  data.allStreams.nodes.forEach((stream) => {
    const isLive =
      !!stream?.channelCreatedAt &&
      (!stream?.channelDestroyedAt || new Date(stream.channelCreatedAt) > new Date(stream.channelDestroyedAt));
    const isJatango = stream?.show?.shop?.slug === 'jatango';
    const streamData: StreamCardData = {
      replayUrl: stream?.streamReplayUrl ?? '',
      isLive,
      isJatango,
      showName: stream?.show?.name ?? '',
      showSlug: stream?.show?.slug ?? '',
      showImageUrl: stream?.show?.showImages?.[0]?.image?.transformedSrc ?? '',
      shopName: stream?.show?.shop?.name ?? '',
      shopSlug: stream?.show?.shop?.slug ?? '',
      shopImage:
        stream?.show?.affiliateShop?.shopCustomizations?.[0]?.shopProfileImage?.transformedSrc ??
        stream?.show?.shop?.shopImages?.nodes?.[0]?.image?.transformedSrc ??
        ''
    };

    if (isJatango) {
      jatangoStreams.push(streamData);
    } else if (isLive) {
      liveStreams.push(streamData);
    } else {
      pastStreams.push(streamData);
    }
  });

  const streamCardData: StreamCardData = {
    replayUrl: "jatango/img_5567.m3u8",
    isLive: false,
    isJatango: true,
    showName: "Jatango",
    showSlug: "jatango-032125-163628",
    showImageUrl: "https://res.cloudinary.com/dq3glbhvz/image/upload/c_crop,g_custom/c_fill/03fd940a-dfd5-4e4e-99bc-0449926840c4.jpg",
    shopName: "Jatango",
    shopSlug: "jatango",
    shopImage: ""
  };

  const jatangoLiveStreams = jatangoStreams.filter((stream) => stream.isLive);

  if (jatangoLiveStreams.length < 1) {
    jatangoLiveStreams.push(streamCardData);
  }
  // Add jatangoStreams to the beginning of liveStreams or pastStreams
  const updatedLiveStreams = [...jatangoLiveStreams, ...liveStreams];
  const updatedPastStreams = [...jatangoStreams.filter((stream) => !stream.isLive), ...pastStreams];

  return { liveStreams: updatedLiveStreams, pastStreams: updatedPastStreams, jatangoStreams };
};

export function useGetAllStreams(first?: number, after?: string) {
  const variables: any = {
    order: [{ channelCreatedAt: OrderDirection.DESC }],
    filter: {
      streamReplayUrl: { neq: null }
    }
  };

  if (first) variables.first = first;
  if (after) variables.after = after;

  const { data, loading, error, refetch } = useGetAllStreamsQuery({ variables });

  const { liveStreams, pastStreams, jatangoStreams } =
    !loading && data ? simplifyApiResponse(data) : { liveStreams: [], pastStreams: [], jatangoStreams: [] };

  return { liveStreams, pastStreams, jatangoStreams, loading, error, refetch };
}

export type StoreProfileShowCardData = {
  replayUrl: string;
  showName: string;
  showImageUrl: string;
};

const extractStoreProfileShowCardData = (data: GetAllStreamsQuery): StoreProfileShowCardData[] => {
  if (!data || !data.allStreams || !data.allStreams.nodes) {
    return [];
  }

  return data.allStreams.nodes.map((stream) => {
    return {
      replayUrl: stream.streamReplayUrl ?? '',
      showName: stream?.show?.name ?? '',
      showImageUrl: stream?.show?.showImages?.[0]?.image?.transformedSrc ?? ''
    };
  });
};

export function useGetAllStreamsByShopSlug(shopSlug: string, first?: number, after?: string) {
  const variables: any = {
    order: [{ createdAt: OrderDirection.DESC }],
    filter: {
      streamReplayUrl: { neq: null }
    }
  };

  if (first) variables.first = first;
  if (after) variables.after = after;

  if (shopSlug) {
    variables.filter.show = {};
    variables.filter.show.shop = { slug: { eq: shopSlug } };
  }

  const { data, loading, error } = useGetAllStreamsQuery({ variables });
  const streams = !loading && data ? extractStoreProfileShowCardData(data) : [];

  return { streams, loading, error };
}

export function useCreateShow() {
  return useRelayMutation<CreateShowMutation, CreateShowMutationVariables, 'createShow'>(
    CreateShowDocument,
    'createShow',
    {
      update(cache, { data }) {
        if (data?.createShow?.show) {
          const showsQuery = cache.readQuery<ShopShowsQuery, ShopShowsQueryVariables>({
            query: ShopShowsDocument,
            variables: {
              shopId: data.createShow?.show.shopId
            }
          });

          if (showsQuery?.showsByShop) {
            cache.writeQuery<ShopShowsQuery, ShopShowsQueryVariables>({
              query: ShopShowsDocument,
              variables: {
                shopId: data.createShow?.show.shopId
              },
              data: {
                ...showsQuery,
                showsByShop: {
                  ...showsQuery.showsByShop,
                  edges: [{ node: data.createShow.show, cursor: '' }, ...(showsQuery.showsByShop.edges ?? [])]
                }
              }
            });
          }
        }
      }
    }
  );
}

export function useInviteAffiliateToShow() {
  return useRelayMutation<InviteShowAffiliateMutation, InviteShowAffiliateMutationVariables, 'inviteShowAffiliate'>(
    InviteShowAffiliateDocument,
    'inviteShowAffiliate'
  );
}

export function useCreateStream() {
  return useRelayMutation<CreateStreamMutation, CreateStreamMutationVariables, 'createStream'>(
    CreateStreamDocument,
    'createStream'
  );
}

export function useUpdateStream() {
  return useRelayMutation<UpdateStreamMutation, UpdateStreamMutationVariables, 'updateStream'>(
    UpdateStreamDocument,
    'updateStream'
  );
}

export function useAddVariantCardToShow() {
  return useRelayMutation<AddVariantCardToShowMutation, AddVariantCardToShowMutationVariables, 'addVariantCardToShow'>(
    AddVariantCardToShowDocument,
    'addVariantCardToShow',
    {
      update(cache, { data }) {
        if (data?.addVariantCardToShow?.variantShowCard) {
          console.log('Updating featured show card');
          const newFeaturedCardRef = cache.writeFragment<FeaturedShowCardFragment>({
            fragmentName: 'FeaturedShowCard',
            fragment: FeaturedShowCardFragmentDoc,
            id: data.addVariantCardToShow.variantShowCard.id,
            data: data.addVariantCardToShow.variantShowCard
          });

          const newCardRef = cache.writeFragment<ShowCardFragment>({
            fragmentName: 'ShowCard',
            fragment: ShowCardFragmentDoc,
            id: data.addVariantCardToShow.variantShowCard.id,
            data: data.addVariantCardToShow.variantShowCard
          });

          cache.modify({
            id: data.addVariantCardToShow.variantShowCard.showId,
            fields: {
              featuredShowCard() {
                return newFeaturedCardRef;
              },
              showCards(existingCardsRef = { nodes: [] }, { readField }) {
                return {
                  ...existingCardsRef,
                  edges: [
                    { node: newCardRef, cursor: '' },
                    ...(existingCardsRef.edges?.filter(
                      (edge: any) =>
                        readField('variantId', edge.node) !== data?.addVariantCardToShow?.variantShowCard?.variantId
                    ) ?? [])
                  ]
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useShowSlugExists(shopSlug: string, showSlug: string, skip?: boolean) {
  const { data, refetch, ...rest } = useCheckShowSlugQuery({
    variables: {
      shopSlug,
      showSlug
    },
    fetchPolicy: 'no-cache',
    skip: skip || !Boolean(shopSlug) || !Boolean(showSlug)
  });

  const checkExists = async (shopSlug: string, showSlug: string) => {
    const result = await refetch({
      shopSlug,
      showSlug
    });
    return Boolean(result?.data?.showBySlug?.id);
  };

  return { slugExists: Boolean(data?.showBySlug?.id), checkExists, ...rest };
}

export function useShopShows(shopId: string, order: any, filter: any, options?: QueryHookOptions<ShopShowsQuery, any>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useShopShowsQuery({
    variables: {
      shopId,
      order,
      filter
    },
    ...options
  });
  const [fetchingMoreShows, setFetchingMoreShows] = useState<boolean>(false);

  const fetchMoreShows = useCallback(async () => {
    setFetchingMoreShows(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.showsByShop?.pageInfo?.endCursor
        }
      });
    } finally {
      setFetchingMoreShows(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShows]);

  return {
    shows: (data?.showsByShop?.edges?.map((edge) => edge.node) ?? []) as ShowFragment[],
    hasMoreShows: data?.showsByShop?.pageInfo?.hasNextPage ?? false,
    fetchingMoreShows,
    fetchMoreShows,
    ...rest
  };
}

export function useFbCommentsSessionByShow(options: {
  showId: string;
  skip?: boolean;
  onError?: (error: string | null, message: string | null) => void;
}) {
  const { data, ...rest } = useFbCommentsSessionByShowQuery({
    variables: { showId: options.showId },
    skip: options.skip
  });
  useFbCommentsStatusByShowSubscription({
    variables: {
      showId: options.showId
    },
    skip: options.skip,
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.fbCommentsSessionStatusByShow) {
        if (subscriptionData.data.fbCommentsSessionStatusByShow.status === FbCommentsSessionStatus.ERROR) {
          options.onError?.(
            subscriptionData.data.fbCommentsSessionStatusByShow.error ?? null,
            subscriptionData.data.fbCommentsSessionStatusByShow.message ?? null
          );
        }
        const commentsSession = client.readFragment<FbCommentsSessionFragment>({
          fragment: FbCommentsSessionFragmentDoc,
          id: subscriptionData.data.fbCommentsSessionStatusByShow.sessionId,
          fragmentName: 'FbCommentsSession'
        });
        if (commentsSession) {
          client.writeFragment<FbCommentsSessionFragment>({
            fragment: FbCommentsSessionFragmentDoc,
            id: subscriptionData.data.fbCommentsSessionStatusByShow.sessionId,
            fragmentName: 'FbCommentsSession',
            data: {
              ...commentsSession,
              status: subscriptionData.data!.fbCommentsSessionStatusByShow!.status,
              error: subscriptionData.data!.fbCommentsSessionStatusByShow!.error,
              message: subscriptionData.data!.fbCommentsSessionStatusByShow!.message
            }
          });
        }
      }
    }
  });
  return { fbCommentsSession: data?.fbCommentsSessionByShow ?? null, ...rest };
}

export function useEnableFbComments() {
  return useRelayMutation<EnableFbCommentsMutation, EnableFbCommentsMutationVariables, 'enableFbComments'>(
    EnableFbCommentsDocument,
    'enableFbComments',
    {
      update(client, { data }) {
        if (data?.enableFbComments.fbCommentsSession) {
          // Write the query to the show session
          client.writeQuery<FbCommentsSessionByShowQuery, FbCommentsSessionByShowQueryVariables>({
            query: FbCommentsSessionByShowDocument,
            variables: { showId: data.enableFbComments.fbCommentsSession.showId },
            data: {
              __typename: 'Query',
              fbCommentsSessionByShow: data.enableFbComments.fbCommentsSession
            }
          });
        }
      }
    }
  );
}

export function useDisableFbComments() {
  return useRelayMutation<DisableFbCommentsMutation, DisableFbCommentsMutationVariables, 'disableFbComments'>(
    DisableFbCommentsDocument,
    'disableFbComments',
    {
      update(client, { data }) {
        if (data?.disableFbComments.fbCommentsSession) {
          // Write the query to the show session
          client.writeQuery<FbCommentsSessionByShowQuery, FbCommentsSessionByShowQueryVariables>({
            query: FbCommentsSessionByShowDocument,
            variables: { showId: data.disableFbComments.fbCommentsSession.showId },
            data: {
              __typename: 'Query',
              fbCommentsSessionByShow: null
            }
          });
        }
      }
    }
  );
}

export function useAcceptAffiliateInvite() {
  return useRelayMutation<
    AcceptAffiliateInviteMutation,
    AcceptAffiliateInviteMutationVariables,
    'acceptAffiliateInvite'
  >(AcceptAffiliateInviteDocument, 'acceptAffiliateInvite');
}

export function useDeclineAffiliateInvite() {
  return useRelayMutation<
    DeclineAffiliateInviteMutation,
    DeclineAffiliateInviteMutationVariables,
    'declineAffiliateInvite'
  >(DeclineAffiliateInviteDocument, 'declineAffiliateInvite');
}

export function useShowCardsByShowSlug(shopSlug: string, showSlug: string) {
  const { data, ...rest } = useShowCardsByShowSlugQuery({
    variables: {
      shopSlug,
      showSlug
    }
  });

  const transformedData = data?.showCardsByShowSlug?.nodes?.map((node) => {
    return {
      ...node,
      productId: node?.variant.productId
    };
  });

  return { cards: transformedData, ...rest };
}

export function useShowBySlugForFbPost(shopSlug: string, showSlug: string) {
  const { data, ...rest } = useGetShowInfoForFbPostQuery({
    variables: {
      filter: {
        shop: {
          slug: { eq: shopSlug }
        },
        slug: { eq: showSlug }
      },
      order: [
        {
          updatedAt: OrderDirection.DESC
        }
      ]
    }
  });
  return { show: data?.shows?.nodes?.[0], ...rest };
}
